<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">
            {{ $t("heading.instance.advanced.edge-cache.title") }}
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="pb-2"
        >
          <auto-updates-card-skeleton v-if="fetchingEdgeCache"/>
          <v-card elevation="4" v-else>
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2"
            >
              <div>
                <h6>
                  {{ $t("heading.instance.advanced.edge-cache.manage.title") }}
                </h6>
                <p class="mb-0 mt-1">
                  {{ $t("heading.instance.advanced.edge-cache.manage.subtitle") }}
                </p>
              </div>
              <div class="d-flex align-center">
                <button-switch
                  class="mt-0"
                  :loading="submittingEdgeCache"
                  :value="edgeCacheStatus"
                  @click.native.stop.prevent="toggleEdgeCache"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          class="pb-2"
        >
          <v-card elevation="4">
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2"
            >
              <div>
                <h6>
                  {{ $t("heading.instance.advanced.edge-cache.purge.title") }}
                </h6>
                <p class="mb-0 mt-1">
                  {{ $t("heading.instance.advanced.edge-cache.purge.subtitle") }}
                </p>
                <AlertSm
                  :class="'alert-sm--primary mt-3'"
                  :text="$t('heading.instance.advanced.edge-cache.purge.alert')"
                />
              </div>
              <div class="d-flex align-center">
                <v-btn
                  elevation="0"
                  class="mt-0 pt-0 ms-2 font-weight-bold v-btn--default"
                  @click="showPurgeEdgeCacheModal"
                >
                  <v-icon size="16" class="font-weight-bold text--gray">$restore</v-icon>
                  <span class="text--gray font-weight-bold">{{ $t('button.purge') }}</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="fetchingEdgeCache"/>
          <v-card elevation="4" v-else>
            <v-card-text class="d-flex justify-space-between text--gray-darken2">
              <div>
                <h6>{{ $t('heading.instance.advanced.edge-cache.defensive-mode.title') }}</h6>
                <p class="mb-6 mt-1 pr-8">
                  {{ $t('heading.instance.advanced.edge-cache.defensive-mode.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <button-switch
                  class="mt-0"
                  :loading="submittingDefensiveMode"
                  :value="defensiveModeStatus"
                  @click.native.stop.prevent="toggleEdgeCacheDefensiveMode"
                />
              </div>
            </v-card-text>
            <v-divider v-if="!defensiveModeStatus"/>
            <v-card-text v-if="!defensiveModeStatus">
              <v-select
                outlined
                hide-details=""
                xlarge
                v-model="defensiveModeTime"
                :items="defensiveModeOptions"
                item-text="label"
                item-value="value"
                return-object
                :menu-props="{
                      offsetY: true,
                    }"
              >
                <template v-slot:no-data>
                  <loader/>
                </template>
              </v-select>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()"/>
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/apis/Api";

import AutoUpdatesCardSkeleton from "../../../components/cards/AutoUpdatesCardSkeleton.vue";
import ButtonSwitch from "../../../components/buttons/ButtonSwitch.vue";
import Loader from "../../../components/Loader.vue";
import AlertSm from "../../../components/alerts/AlertSm.vue";
import BasicModal from "../../../components/modal/BasicModal.vue";
import ActionModalMixin from "../../../mixins/ActionModalMixin";

export default {
  name: "EdgeCache",
  components: {
    BasicModal,
    AlertSm,
    Loader,
    AutoUpdatesCardSkeleton,
    ButtonSwitch,
  },
  mixins: [ActionModalMixin],
  props: {
    instance: Object,
  },
  data() {
    return {
      fetchingEdgeCache: false,
      submittingEdgeCache: false,
      edgeCacheStatus: false,

      fetchingDefensiveMode: false,
      submittingDefensiveMode: false,
      defensiveModeStatus: false,
      defensiveModeTime: {
        label: this.$t('general.time.minute', {count: 30}),
        value: 1800
      },
      defensiveModeOptions: [
        {
          label: this.$t('general.time.minute', {count: 30}),
          value: 1800
        },
        {
          label: this.$t('general.time.hour', {count: 1}),
          value: 3600
        },
        {
          label: this.$t('general.time.hour', {count: 8}),
          value: 28800
        },
        {
          label: this.$t('general.time.hour', {count: 24}),
          value: 86400
        },
        {
          label: this.$t('general.time.day', {count: 7}),
          value: 604800
        },
      ],
      applicationId: this.$route.params.id,
    };
  },
  mounted() {
    this.fetchEdgeCacheStatus();
  },
  methods: {
    fetchEdgeCacheStatus() {
      this.fetchingEdgeCache = true;
      Api.get(`/instances/${this.applicationId}/wordpress/edge-cache`)
        .then((response) => {
          this.edgeCacheStatus = response.data.isActive;
          this.defensiveModeStatus = response.data.isDefensiveMode;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingEdgeCache = false;
        });
    },
    toggleEdgeCache() {
      this.submittingEdgeCache = true;
      Api.put(`/instances/${this.applicationId}/wordpress/edge-cache`, {
        status: !this.edgeCacheStatus,
      })
        .then(() => {
          this.edgeCacheStatus = !this.edgeCacheStatus;

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.edgeCacheStatus
              ? this.$t("notification.instance.edge-cache.manage.enable.success")
              : this.$t("notification.instance.edge-cache.manage.disable.success"),
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.submittingEdgeCache = false;
        })
    },
    showPurgeEdgeCacheModal() {
      this.resetModal();
      this.modalOptions.icon = "$restore";
      this.modalOptions.title = this.$t('heading.instance.advanced.edge-cache.modal.purge.title');
      this.modalOptions.message = this.$t('heading.instance.advanced.edge-cache.modal.purge.info');

      this.modalOptions.buttons.unshift({
        label: this.$t('button.purge'),
        color: "primary",
        onclick: () => {
          this.purgeEdgeCache();
        },
      });
      this.modalOptions.open = true;
    },
    purgeEdgeCache() {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.put(`/instances/${this.applicationId}/wordpress/edge-cache/purge`)
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage:  this.$t("notification.instance.edge-cache.purge.success"),
          });
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
    toggleEdgeCacheDefensiveMode() {
      this.submittingDefensiveMode = true;
      Api.put(`/instances/${this.applicationId}/wordpress/edge-cache/defensive-mode`, {
        status: !this.defensiveModeStatus,
        ttl: this.defensiveModeTime.value
      })
        .then(() => {
          this.defensiveModeStatus = !this.defensiveModeStatus;

          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.defensiveModeStatus
              ? this.$t("notification.instance.edge-cache.defensive-mode.enable.success")
              : this.$t("notification.instance.edge-cache.defensive-mode.disable.success"),
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.submittingDefensiveMode = false;
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card--mobile {
  @media (max-width: 767px) {
    flex-direction: column;
    .v-btn--default {
      margin-top: 16px;
    }
  }
}
</style>