<template>
    <div class="alert-sm">
        <div class="alert-sm-icon mr-2">
            <v-icon size="16">$infoCircle</v-icon>
        </div>
        <div class="alert-sm-text text--gray-darken1">
            <span class="p-sm">{{ text }}</span>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        text: {
            class: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
    },
};

</script>

<style lang="scss" scoped>
.alert-sm{
    display: flex;
    align-items: center;

    &.alert-sm{
        &--primary{
            .alert-sm-icon{
                span{
                    color: map-get($primary, base);
                }
            }
        }
    }
    .alert-sm{
        &-icon{
            margin-top: -2px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-text{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>